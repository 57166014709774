import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export const API_URI = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public userEmitter = new BehaviorSubject(null);
  public userObservable = this.userEmitter.asObservable();

  constructor(private _http: HttpClient) {}

  /** GET REQUEST */
  public get(url: string, _token: string | null = null): Observable<any> {
    return this._http.get(`${API_URI}/${url}`, this._getOptions(_token));
  }

  /** POST REQUEST */
  public post(
    url: string,
    params: any,
    _token: string | null = null
  ): Observable<any> {
    return this._http.post(
      `${API_URI}/${url}`,
      params,
      this._getOptions(_token)
    );
  }

  /** PUT REQUEST */
  public put(
    url: string,
    params: any,
    _token: string | null = null
  ): Observable<any> {
    return this._http.put(
      `${API_URI}/${url}`,
      params,
      this._getOptions(_token)
    );
  }

  /** DELETE REQUEST */
  public delete(url: string, _token: string | null = null): Observable<any> {
    return this._http.delete(`${API_URI}/${url}`, this._getOptions(_token));
  }

  /** HTTP HEADERS */
  private _getOptions(_token: string | null = null) {
    let token: string | null = !!_token
      ? _token
      : localStorage.getItem('token');

    let httpOptions = {
      headers: new HttpHeaders({
        observe: 'response',
        responseType: 'json',
        Authorization: 'Bearer ' + token,
      }),
    };
    return httpOptions;
  }

  public getImage(url: string): Observable<any> {
    return this._http.get(`${API_URI}/${url}`, {
      headers: this._getBlobOptions(),
      responseType: 'blob',
    });
  }

  private _getBlobOptions() {
    let token: string | null = environment.token;

    let httpOptions = {
      Authorization: 'Bearer ' + token,
    };
    return httpOptions;
  }
}
