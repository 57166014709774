import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { SwalService } from 'src/app/services/swal.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanLoad {
  constructor(
    private _api: ApiService,
    private _router: Router,
    private _swal: SwalService
  ) {
    console.log('AuthGuard constructor');
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._api.post('sanctum/user', {}).pipe(
      map((response: { user: boolean }) => {
        console.log('AuthGuard canLoad response', response);

        let user: any = response.user;

        if (!user || !user.stripe_customer_id) {
          this._router.navigate(['/']);
        }

        this._api.userEmitter.next(user);

        return !!user;
      }),
      catchError((error: any) => {
        console.log('AuthGuard catchError error', error);

        this._swal.error(error);

        return of(true);
      })
    );
  }
}
