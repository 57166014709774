import { Injectable, ViewChild } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { SwalService } from 'src/app/services/swal.service';

@Injectable({
  providedIn: 'root',
})
export class GuestGuard implements CanLoad {
  constructor(
    private _api: ApiService,
    private _router: Router,
    private _swal: SwalService
  ) {
    console.log('GuestGuard constructor');
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._api.post('sanctum/check', {}).pipe(
      map((response: { check: boolean }) => {
        console.log('GuestGuard canLoad response', response);

        if (!!response.check) {
          this._router.navigate(['/admin']);
        }

        return !response.check;
      }),
      catchError((error: any) => {
        console.log('GuestGuard catchError error', error);

        this._swal.error(error);

        return of(true);
      })
    );
  }
}
