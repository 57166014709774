import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { GuestGuard } from 'src/app/guards/guest.guard';

const routes: Routes = [
  {
    path: 'admin',
    canLoad: [AuthGuard],
    loadChildren: () =>
      import('src/app/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('src/app/components/pages/home-page/home-page.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: 'login',
    canLoad: [GuestGuard],
    loadChildren: () =>
      import('src/app/components/pages/login-page/login-page.module').then(
        (m) => m.LoginPageModule
      ),
  },
  {
    path: 'register',
    canLoad: [GuestGuard],
    loadChildren: () =>
      import(
        'src/app/components/pages/register-page/register-page.module'
      ).then((m) => m.RegisterPageModule),
  },
  {
    path: 'about-us',
    loadChildren: () =>
      import('src/app/components/pages/about-page/about-page.module').then(
        (m) => m.AboutPageModule
      ),
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('src/app/components/pages/blog-page/blog-page.module').then(
        (m) => m.BlogPageModule
      ),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('src/app/components/pages/contact-page/contact-page.module').then(
        (m) => m.ContactPageModule
      ),
  },
  {
    path: 'services',
    loadChildren: () =>
      import(
        'src/app/components/pages/services-page/services-page.module'
      ).then((m) => m.ServicesPageModule),
  },
  {
    path: 'services/beauty-treatment',
    loadChildren: () =>
      import(
        'src/app/components/pages/services/beauty-treatment-service-page/beauty-treatment-service-page.module'
      ).then((m) => m.BeautyTreatmentServicePageModule),
  },
  {
    path: 'services/hormone-therapy',
    loadChildren: () =>
      import(
        'src/app/components/pages/services/hormone-therapy-service-page/hormone-therapy-service-page.module'
      ).then((m) => m.HormoneTherapyServicePageModule),
  },
  {
    path: 'services/iv-infusion-therapy',
    loadChildren: () =>
      import(
        'src/app/components/pages/services/iv-infusion-therapy-service-page/iv-infusion-therapy-service-page.module'
      ).then((m) => m.IvInfusionTherapyServicePageModule),
  },
  {
    path: 'services/lab-tests',
    loadChildren: () =>
      import(
        'src/app/components/pages/services/lab-tests-service-page/lab-tests-service-page.module'
      ).then((m) => m.LabTestsServicePageModule),
  },
  {
    path: 'services/ozone-therapy-treatment',
    loadChildren: () =>
      import(
        'src/app/components/pages/services/ozone-therapy-treatment-service-page/ozone-therapy-treatment-service-page.module'
      ).then((m) => m.OzoneTherapyTreatmentServicePageModule),
  },
  {
    path: 'services/peptide-therapy',
    loadChildren: () =>
      import(
        'src/app/components/pages/services/peptide-therapy-service-page/peptide-therapy-service-page.module'
      ).then((m) => m.PeptideTherapyServicePageModule),
  },
  {
    path: 'services/primary-care',
    loadChildren: () =>
      import(
        'src/app/components/pages/services/primary-care-service-page/primary-care-service-page.module'
      ).then((m) => m.PrimaryCareServicePageModule),
  },
  {
    path: 'services/weight-loss',
    loadChildren: () =>
      import(
        'src/app/components/pages/services/weight-loss-service-page/weight-loss-service-page.module'
      ).then((m) => m.WeightLossServicePageModule),
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
